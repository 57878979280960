.Header {
	position: sticky;
    top: 0;
    padding-left: 10%;
    padding-right: 10%;
    /* color: white; */
    /* background-color: #323232; */
    background-color: white;
}

.Left {
    float: left;
    font-size: 36px;
    font-family: 'Helvetica';
    color: hsla(209, 15%, 28%);
    padding-top: 4px;
    /* font-family: 'Playfair Display SC', serif; */
}

.Right {
    float: right;
}

.Header::after {
    content: "";
    clear: both;
    display: table;
}

.SearchBtn {
    background-color: hsl(170, 100%, 40%);
    /* background-color: #5680E9; */
    font-size: 18px;
    /* font-family: 'Montserrat', sans-serif; */
}

.Nav {
    display: flex;
    flex-direction: row;
}

/* .NavItem:hover { */
    /* text-decoration: underline;
    color: #84CEEB; */
    /* background-color: white; */
    /* color: #0079D3;
    border-bottom: 5px solid #0079D3; */
/* } */

.link {
    text-decoration: none !important;
    color: black;
    margin-left: 24px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 8px;
}

.link:hover{
    color: #0079D3;
    border-bottom: 5px solid #0079D3;

}

.NavItem {
    /* margin-left: 24px; */
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 8px;
}



/* .NavItem > input {
    margin: 10px;
    margin: 0 10px 0 10px;
} */


.NavInput {
    font-size: 16px;
    margin-left: 8px;
    padding: 8px 12px;
    margin-bottom: 8px;
    border-radius: 3px;
    border:1px solid hsla(208, 12%, 58%);
    box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}

.NavBtn {
    font-size: 16px;
    border-radius: 3px;
    border: none;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px 24px;
    color: white;
    background-color: hsl(170, 100%, 40%);
    /* background-color: #0079D3; */
}

.NavBtn:hover {
    background-color: #4ab2ff;
    color: white;
}

.Icon {
    border-radius: 50%;
    background-color: #C1C8E4;
    color: black;
    height: 2em;
    width: 2em;
    padding-top: 7px;
    margin-top: 15px;
}