* {
    box-sizing: border-box;
}



.Home {
  text-align: left;
  padding-bottom: 72px;
}

.Left {
  float: left;
  width: 50%;
  /* color: hsla(209, 15%, 28%); */
  color: #014D40;
  padding-top: 72px;
}

.Left h1 {
  /* font-family: 'Vidaloka', serif; */
  font-size: 30px; 
  margin-top: 0;
}

.Left p {
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 16px;
  line-height: 1.5;
}

.Right {
  float: left;
  width: 50%;
  padding-left: 48px;
  color: hsla(209, 15%, 28%);
  /* font-family: 'Montserrat', sans-serif; */
  padding-top: 72px;
}

.Home::after {
  content: "";
  clear: both;
  display: table;
}

.NewAccountTitle {
    font-size: 24px;
    color: hsla(209, 15%, 28%);
    margin-top: 0;
    margin-bottom: 0;
}

@media (max-width: 900px) {
  .Left {
    width: 100%;
  }

  .Right {
    padding-left: 0px;
    width: 100%;
    margin-top: 24px;
  }
}