.Layout {
	/* background-color: #84CEEB;	 */
}

.Content {
	padding-left: 10%;
	padding-right: 10%;
	margin-left: 0;
	margin-right: 0;
}

body {
  /* background-color: #84CEEB; */
  /* background-color: #DAE0E6; */
  background-color: hsla(180, 28%, 90%) !important;
}
