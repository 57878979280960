* {
    box-sizing: border-box;
}

.PageStyles {
    padding-top: 5%;
}

.LeftPanel {
    width: 30%;
    height: 100%;
    float: left;
    /* padding-right: 50px;
    margin-top: 15px; */
}

.RightPanel {
    width: 70%;
    float: left;
    text-align: left;
    margin-top: 15px;
}

.BigPrompt {
    font-size: 30px;
    text-align: left;;
    font-family: 'Vidaloka', serif;
    padding-top:0px;
    margin-top: 0px;
}

.SortBox {
    height: 100%;
    /* background-color: white; */
}

.AvailablePrompt {
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
}