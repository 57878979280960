.AvailableHomeBox {
    width: 100%;
    overflow: auto;
    box-shadow: 0 0 2px -2px #888;
    padding: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: white;
    position: relative;
}

.DistanceBox {
    font-size: 24px;
    color: #888;
    border-right: 1px solid #888;
    float: left;
    width: 20%;
}

.Distance {
    position: relative;
    top: 50%;
    
}

.HomeDetails {
    text-align: left;
    float: left;
    width: 80%;
    padding-left: 30px;
}

.Address {
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 24px;
}

.ChargeBtn {
    color: white;
    /* background-color: #8860D0; */
    background-color: #c0c0c0;
    font-size: 18px;
    padding: 4px 12px;
}

.HomeContainer {
    opacity: 0;
    min-height: 100px;
    /* transition: opacity 2s ease; */
    /* -moz-transition:    opacity 2s;
    -ms-transition:     opacity 2s;
    -o-transition:      opacity 2s;
    -webkit-transition: opacity 2s;
    transition-delay: 2s; */
}

.topRightIcon {
    padding: 8px 12px;
    font-size: 10px;
    border-radius: 30px;
    background-color: #ffe5e5;
    color: #b20000;
    position: absolute;
    top: 12px;
    right: 12px;
}

.fade {
    -webkit-animation: fade 2s;
    animation: fade 2s;
    opacity: 1;
    min-height: 600px;
}

@-webkit-keyframes fade {
    from {opacity: 0; min-height: 100px}
    to {opacity: 1; min-height: 600px}
}@keyframes fade {
    from {opacity: 0; min-height: 100px}
    to {opacity: 1; min-height: 600;}
}