.FormInput {
    font-size: 16px;
    padding: 8px 12px;
    margin-top: 18px;
    border: 1px solid hsla(208, 12%, 58%);
    border-radius: 3px;
    width: 100%;
    box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}

.FirstName {
    width: 40%;
    display: inline-block;
}

.LastName {
    width: 60%;
    padding-left: 16px;
    display: inline-block;
}

.SignupBtn {
    width: 100%;
    margin-top: 18px;
    border-radius: 3px;
    /* background-color: hsl(170, 100%, 40%); */
    background-color: hsl(205.6,100%,41.4%);
    color: white;
    font-size: 18px;
    padding: 12px;
    border: none;
}

.SignupBtn:hover {
  /* background-color: hsl(170, 100%, 45%); */
  background-color: hsl(222.9,77%,62.5%);
}