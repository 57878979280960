

.NoVehiclesMessage {
    background-color: #c0c0c0;
    padding: 20px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AddVehicleBtn {
    background-color: #0079D3;
    padding: 10px 25px;
    border-radius: 3px;
    font-size: 16px;
    color: white;
    margin-bottom: 15px;
    margin-top: 15px;
    cursor: pointer;
}

.AddVehicleBtn:hover {
    background-color: #4ab2ff;
}

.EditBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.EditSubmitBtn {
    background-color: white;
    border: 3px solid #0079D3;
    padding: 10px 15px;
    font-size: 14px;
    margin-left: 20px;
    color: #0079D3;
}

.EditSubmitBtn:hover {
    background-color: #0079D3;
    color: white;
    cursor: pointer;
    
}

.SaveBtn {
    background-color: white;
    border: 3px solid #0079D3;
    border-radius: 4px;
    color: #0079D3;
    font-size: 16px;
    padding: 10px 25px;
    margin-top: 15px;
    cursor: pointer;
}

.SaveBtn:hover {
    background-color: #0079D3;
    color: white;
}

.StaticVehicle {
    box-sizing: content-box;
    background-color: white;
    border-radius: 3px;
    padding: 35px;
    margin-bottom: 5px;
    font-weight: bold;
    overflow: hidden;
    position: relative;
}

.StaticVehicle:hover {
    /* box-shadow: 2px 2px 2px 0 #888; */
    border: 1px solid #888;
}

.StaticVehicle > p {
    margin: 0;
    padding: 0;
}

.DefaultVehicleRadio {
    float: left;
    width: 5%;
    border-radius: 50%;
    margin-top: 15px;
    margin-right: 35px;
    height: 20px;
    width: 20px;
    background-color: #0079D3;
    cursor: pointer;
}

.DefaultVehicleRadio:hover {
    box-shadow: 0px 0px 2px 2px #0079D3;
}

.NonDefaultVehicleRadio {
    float: left;
    margin-top: 15px;
    width: 10%;
    border-radius: 50%;
    margin-right: 35px;
    height: 20px;
    width: 20px;
    border: 3px solid #0079D3;
    cursor: pointer;
}

.NonDefaultVehicleRadio:hover {
    box-shadow: 0px 0px 2px 2px #0079D3;
}

.FormSelect {
    width: 16%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    appearance: none;
    background-position: right 50%;
    font-size: 15px;
    border-radius: 0px;
    float: left;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.FormInput {
    width: 18%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    border: 1px solid gray;
    border-radius: 0px;
    font-size: 15px;
}

.TrashDiv {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}