.Left > p {
    margin: 0px;
}

.UserCarSelector {
    /* padding: 15px; */
    /* background-color: white; */
}

.UsedCarSelector > p {
    margin: 0;
}

.UserCar {
    background-color: white;
    margin-right: 30px;
    text-align: left;
    padding: 0px 0px 0px 10px;
    margin-top: 5px;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
}

.UserCar:hover {
    background-color: #4ab2ff;
    color: white;
    /* box-shadow: 2px 2px 2px solid #888; */
}

.UserCarUnselected {
    border: 1px solid #c0c0c0;
}

.UserCarSelected {
    border: 1px solid #0079D3;
}

.CheckBoxUnselected {
    background-color: #c0c0c0;
}

.CheckBoxSelected {
    color: white;
    background-color: #0079D3;
    /* padding-top: 25px; */
    /* align-self: center; */
}

.Left {
    width: 75%;
    padding: 10px;
}

.Right {
    width: 25%;
    font-size: 50px;
    text-align: center;
    
}