
* {
	box-sizing: border-box;
}

.Modal {
	width: 100%;
	height: 100%;
	overflow: auto;
	position: fixed;
	left: 0;
	top: 0;
	background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
}

.visible {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s linear;
}

.hidden {
	opacity: 0;
	visibility: hidden;
	box-shadow: 1rem 0 0 rgba(0,0,0,0.2);
	transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.ModalContent {
	position: relative;
	width: 50%;
	margin: auto;
	top: 10%;
	background: white;
	border: 1px solid #ccc;
	box-shadow: -2rem 2rem 2rem rgba(0,0,0,0.2);
	/*animation-name: animatetop;
  	animation-duration: 0.5s;*/
}

h2 {
	border-bottom: 1px solid #ccc;
	padding: 1rem;
	margin: 0;
}


.Actions {
	border-top: 1px solid #ccc;
	background: #eee;
	line-height: 1;
	padding: 0.5rem 1rem;
}

.actions button {
  border: 0;
  background: #5680E9;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

.buttonStyles {
	border: none;
	background-color: maroon;
	color: white;
	font-size: 20px;
	position: absolute;
	top: 10px;
	right: 10px;
}


/*@keyframes animatetop {
  from {top: 0px; opacity: 0}
  to {top: 10%; opacity: 1}
}*/